import React from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import Footer from '../../components/Footer/Footer';
import ChildrenProp from '../../prop-types/ChildrenProp';
import styles from './DefaultTemplate.module.scss';

const DefaultTemplate = ({ children }) => (
  <>
    <Helmet defaultTitle="Britannica" titleTemplate="Britannica Community Access Manager | %s" />
    <main className={classnames(styles.content)}>{children}</main>
    <Footer className={styles.footer} />
  </>
);

DefaultTemplate.propTypes = {
  children: ChildrenProp.isRequired,
};

export default DefaultTemplate;
