import React, { lazy, Suspense } from 'react';

const LazyEmbeddedRegistrationPage = lazy(() => import('./EmbeddedRegistrationPage'));

const EmbeddedRegistrationPage = (props) => (
  <Suspense fallback={null}>
    <LazyEmbeddedRegistrationPage {...props} />
  </Suspense>
);

export default EmbeddedRegistrationPage;
