import React, { lazy, Suspense } from 'react';

const LazyRegistrationSuccessPage = lazy(() => import('./RegistrationSuccessPage'));

const RegistrationSuccessPage = (props) => (
  <Suspense fallback={null}>
    <LazyRegistrationSuccessPage {...props} />
  </Suspense>
);

export default RegistrationSuccessPage;
