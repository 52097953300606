import React, { lazy, Suspense } from 'react';

const LazySettingsPage = lazy(() => import('./SettingsPage'));

const SettingsPage = (props) => (
  <Suspense fallback={null}>
    <LazySettingsPage {...props} />
  </Suspense>
);

export default SettingsPage;
