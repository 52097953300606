import React, { lazy, Suspense } from 'react';

const LazyEmbeddedNewPasswordPage = lazy(() => import('./EmbeddedNewPasswordPage'));

const EmbeddedNewPasswordPage = (props) => (
  <Suspense fallback={null}>
    <LazyEmbeddedNewPasswordPage {...props} />
  </Suspense>
);

export default EmbeddedNewPasswordPage;
