import { Auth } from '@aws-amplify/auth';
import { merge } from 'lodash-es';

const CamService = {
  API_URL: process.env.REACT_APP_BACKEND_API_URL,
  API_URL_2: process.env.REACT_APP_BACKEND_API_URL_2,

  /**
   * Fetch wrapper that contains default headers needed for making an authenticated request
   * @param {string} url
   * @param {object} [options]
   * @param {string} [apiUrl]
   * @returns {Promise<object>}
   */

  async fetch(url, options, apiUrl = CamService.API_URL) {
    try {
      const { jwtToken: idToken } = (await Auth.currentSession()).getIdToken();

      return fetch(
        `${apiUrl}${url}`,
        merge(
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          },
          options
        )
      ).then(async (response) => {
        if (!response.ok) {
          throw await response.json();
        }

        return response.json();
      });
    } catch (error) {
      throw new Error(error);
    }
  },

  /**
   * Update currently logged in user's profile
   * @param {string} firstName
   * @param {string} lastName
   * @param {string} shortBio
   * @param {string} profileAvatarImage
   * @returns {Promise<Object>}
   */

  async updateUserProfile({ firstName, lastName, shortBio, profileAvatarImage }) {
    return CamService.fetch('/userProfile', {
      method: 'post',
      body: JSON.stringify({ firstName, lastName, shortBio, profileAvatarImage }),
    });
  },

  /**
   * Fetch currently logged in user's profile
   * @returns {Promise<Object>}
   */

  async fetchUserProfile() {
    return CamService.fetch('/userProfile');
  },

  /**
   * Fetch currently logged in user's preferences like notifications
   * @returns {Promise<Object>}
   */

  async getUserPreferences() {
    return CamService.fetch('/userpreferences');
  },

  /**
   * Update currently logged in user's preferences
   * @param {object} preferences - with different preference keys
   * @returns {Promise<Object>}
   */

  async postUserPreferences(preferences) {
    return CamService.fetch('/userpreferences', {
      method: 'post',
      body: JSON.stringify(preferences),
    });
  },

  /**
   * Migrate a user from the Login form
   * @param email
   * @param password
   * @param reCaptchaToken
   * @returns {Promise<Response>}
   */

  async migrateUserFromLoginForm({ email, password, reCaptchaToken = 'recapcha' }) {
    return fetch(`${CamService.API_URL_2}/info/cam/checkUserStatusLogin`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
        reCaptchaToken,
      }),
    }).then((response) => response.json());
  },

  /**
   * Migrate a user from the Forgot Password form
   * @param email
   * @param reCaptchaToken
   * @returns {Promise<Response>}
   */

  async migrateUserFromForgotPasswordForm({ email, reCaptchaToken = 'recapcha' }) {
    return fetch(`${CamService.API_URL_2}/info/cam/checkUserStatusForgotPassword`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        reCaptchaToken,
      }),
    }).then((response) => response.json());
  },

  /**
   * Get the "AuthTokenExpirable2" cookie
   * @returns {Promise<Object>}
   */

  async getOracleAuthCookie() {
    return CamService.fetch('/api/cam/authCookie', {}, CamService.API_URL_2);
  },
};

export default CamService;
