import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Auth } from '@aws-amplify/auth';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Input, Button } from '@britannica/ui';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useFormValidationEvents from '../../hooks/useFormValidationEvents';
import LoadingButton from '../LoadingButton/LoadingButton';
import { Routes } from '../../constants';
import CustomToast from '../CustomToast/CustomToast';

const VerificationForm = ({ embedded }) => {
  const validationSchema = object().shape({
    code: string()
      .required()
      .matches(/(^[\S]+$)/, 'Whitespace not allowed'),
  });
  const [username, setUsername] = useState(null);
  const [email, setEmail] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [resent, setResent] = useState(false);
  const history = useHistory();
  const { register, handleSubmit, errors, formState } = useForm({ resolver: yupResolver(validationSchema) });
  const TitleTag = embedded ? 'h2' : 'h1';
  const formRef = useFormValidationEvents(formState);

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);

    setEmail(params.get('email')?.toLowerCase());
    setUsername(params.get('user')?.toLowerCase());
  }, [history.location]);

  async function onSubmit(data) {
    try {
      await Auth.confirmSignUp(username || email, data.code, {
        // Optional. Force user confirmation irrespective of existing alias. By default set to True.
        forceAliasCreation: false,
      });

      if (embedded) {
        history.push(Routes.EMBEDDED_REGISTRATION_SUCCESS.toLink());
      } else {
        history.push(Routes.REGISTRATION_SUCCESS.toLink());
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  }

  function onClosedHandler() {
    setErrorMessage(null);
  }

  async function resend() {
    try {
      await Auth.resendSignUp(username);

      setResent(true);
    } catch (error) {
      setErrorMessage(error.message);
    }
  }

  return (
    <div ref={formRef}>
      <div className="card-title mb-30">
        <TitleTag className="mb-20 font-weight-bold">Confirm Your Britannica Account</TitleTag>
        <div className="font-serif">We just sent a code to your inbox. Please enter the code below.</div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="m-5">
        <div className="mb-20">
          <label htmlFor="code" className="label required">
            Enter code
          </label>
          <Input placeholder="Enter code" type="text" name="code" isInvalid={!!errors.code} innerRef={register} />
          {errors?.code?.message && (
            <div className="text-red font-12 mt-5 font-serif first-letter-uppercase">{errors.code.message}</div>
          )}
        </div>
        {email && (
          <div className="mb-20">
            <p className="label">SENT TO</p>
            <div className="font-serif text-gray-700">{email}</div>
          </div>
        )}

        {!email && username && (
          <div className="mb-20">
            <p className="label">SENT TO ACCOUNT</p>
            <div className="font-serif text-gray-700">{username}</div>
          </div>
        )}

        <div>
          {!resent && (
            <Button
              label="Resend code"
              color="link"
              onClick={resend}
              className="p-0 text-capitalize h-auto border-0 font-14"
            >
              Resend code
            </Button>
          )}
          {resent && <div className="mt-10">A verification code has been resent to your email</div>}
        </div>

        <div className="text-center">
          <LoadingButton
            label="Continue"
            loading={formState.isSubmitting}
            color="blue"
            className="w-100 mt-30"
            type="submit"
          >
            Continue
          </LoadingButton>
        </div>
        {errorMessage && <CustomToast onClosed={onClosedHandler}>{errorMessage}</CustomToast>}
      </form>
    </div>
  );
};

VerificationForm.propTypes = {
  embedded: PropTypes.bool,
};

VerificationForm.defaultProps = {
  embedded: false,
};

export default VerificationForm;
