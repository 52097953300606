import React, { lazy, Suspense } from 'react';

const LazyForgetPage = lazy(() => import('./ForgotPasswordPage'));

const ForgetPage = (props) => (
  <Suspense fallback={null}>
    <LazyForgetPage {...props} />
  </Suspense>
);

export default ForgetPage;
