import { generateRoutes } from './utils/routing-utils';
import variables from './styles/_variables.scss';

export const ENV = process.env.REACT_APP_ENV;

// Global Routes

export const Routes = generateRoutes({
  CHANGE_AVATAR: '/profile/changeavatar',
  CHANGE_BIO: '/profile/changebio',
  CHANGE_NAME: '/profile/changename',
  CHANGE_PASSWORD: '/settings/changepassword',
  DELETE_ACCOUNT: '/deleteaccount',
  EMBEDDED_FORGOT_PASSWORD: '/embed/forgot-password',
  EMBEDDED_LOGIN: '/embed/login',
  EMBEDDED_MIGRATION_SUCCESS: '/embed/migration-success',
  EMBEDDED_NEW_PASSWORD: '/embed/newpassword',
  EMBEDDED_PASSWORD_RESET: '/embed/passwordreset',
  EMBEDDED_REGISTRATION: '/embed/registration',
  EMBEDDED_REGISTRATION_SUCCESS: '/embed/success',
  EMBEDDED_UPDATE_PASSWORD: '/embed/update-password',
  EMBEDDED_VERIFICATION: '/embed/verification',
  FORGOT_PASSWORD: '/forgot-password',
  HOME: '/',
  LOGIN: '/login',
  MIGRATION_SUCCESS: '/migration-success',
  NEW_PASSWORD: '/newpassword',
  NOTIFICATIONS: '/notifications',
  PASSWORD_RESET: '/passwordreset',
  PRODUCTS: '/products',
  PROFILE: '/profile',
  REGISTRATION: '/registration',
  REGISTRATION_SUCCESS: '/success',
  SETTINGS: '/settings',
  TEST: '/test',
  UPDATE_PASSWORD: '/update-password',
  VERIFICATION: '/verification',
});

export const ViewportWidth = {
  XS_MAX: parseInt(variables.screenXsMax),
  SM_MIN: parseInt(variables.screenSmMin),
  SM_MAX: parseInt(variables.screenSmMax),
  MD_MIN: parseInt(variables.screenMdMin),
  MD_MAX: parseInt(variables.screenMdMax),
  LG_MIN: parseInt(variables.screenLgMin),
  LG_MAX: parseInt(variables.screenLgMax),
  XL_MIN: parseInt(variables.screenXlMin),
};

export const MediaQuery = {
  XS: `(max-width: ${variables.screenXsMax})`,
  SM: `(min-width: ${variables.screenSmMin}) and (max-width: ${variables.screenSmMax})`,
  MD: `(min-width: ${variables.screenMdMin}) and (max-width: ${variables.screenMdMax})`,
  LG: `(min-width: ${variables.screenLgMin}) and (max-width: ${variables.screenLgMax})`,
  LG_UP: `(min-width: ${variables.screenLgMin})`,
  XL: `(min-width: ${variables.screenXlMin})`,
};

// Context

export const SELECT_PROFILE = 'SELECT_PROFILE';
export const ERROR_PROFILE = 'ERROR_PROFILE';
export const LOADING_PROFILE = 'LOADING_PROFILE';
