import React, { lazy, Suspense } from 'react';

const LazyEmbeddedRegistrationSuccessPage = lazy(() => import('./EmbeddedRegistrationSuccessPage'));

const EmbeddedRegistrationSuccessPage = (props) => (
  <Suspense fallback={null}>
    <LazyEmbeddedRegistrationSuccessPage {...props} />
  </Suspense>
);

export default EmbeddedRegistrationSuccessPage;
