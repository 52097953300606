import React from 'react';
import { render } from 'react-dom';
import App from './App';
import './index.scss';

// Enable logging when the localStorage key "cam:*" exists

if (process.env.REACT_APP_ENV !== 'production') {
  localStorage.setItem('debug', 'cam:*');
}

render(<App />, document.querySelector('#root'));
