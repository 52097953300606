import { PostMessageEvent } from '@britannica/cam-utils';
import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ENV, Routes } from './constants';
import { emit } from './utils/embed-utils';
import EmbeddedForgotPasswordPage from './pages/EmbeddedForgotPasswordPage/EmbeddedForgotPasswordPage.lazy';
import EmbeddedLoginPage from './pages/EmbeddedLoginPage/EmbeddedLoginPage.lazy';
import EmbeddedMigrationSuccessPage from './pages/EmbeddedMigrationSuccessPage/EmbeddedMigrationSuccessPage.lazy';
import EmbeddedNewPasswordPage from './pages/EmbeddedNewPasswordPage/EmbeddedNewPasswordPage.lazy';
import EmbeddedPasswordResetPage from './pages/EmbeddedPasswordResetSuccessPage/EmbeddedPasswordResetSuccessPage.lazy';
import EmbeddedRegistrationPage from './pages/EmbeddedRegistrationPage/EmbeddedRegistrationPage.lazy';
import EmbeddedSuccessPage from './pages/EmbeddedRegistrationSuccessPage/EmbeddedRegistrationSuccessPage.lazy';
import EmbeddedUpdatePasswordPage from './pages/EmbeddedUpdatePasswordPage/EmbeddedUpdatePasswordPage.lazy';
import EmbeddedVerificationPage from './pages/EmbeddedVerificationPage/EmbeddedVerificationPage.lazy';
import LoginPage from './pages/LoginPage/LoginPage.lazy';
import MigrationSuccessPage from './pages/MigrationSuccessPage/MigrationSuccessPage.lazy';
import RegistrationPage from './pages/RegistrationPage/RegistrationPage.lazy';
import SuccessPage from './pages/RegistrationSuccessPage/RegistrationSuccessPage.lazy';
import TestPage from './pages/TestPage/TestPage.lazy';
import UpdatePasswordPage from './pages/UpdatePasswordPage/UpdatePasswordPage.lazy';
import VerificationPage from './pages/VerificationPage/VerificationPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage.lazy';
import NewPasswordPage from './pages/NewPasswordPage/NewPasswordPage.lazy';
import EnterNewPasswordPage from './pages/EnterNewPasswordPage/EnterNewPasswordPage.lazy';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import PasswordResetSuccessPage from './pages/PasswordResetSuccessPage/PasswordResetSuccessPage.lazy';
import ProfilePage from './pages/ProfilePage/ProfilePage.lazy';
import EditProfilePage from './pages/EditProfilePage/EditProfilePage.lazy';
import SettingsPage from './pages/SettingsPage/SettingsPage.lazy';
import ProductsPage from './pages/ProductsPage/ProductsPage.lazy';
import NotificationsPage from './pages/NotificationsPage/NotificationsPage.lazy';
import { getRouteName } from './utils/routing-utils';

const AppRoutes = () => {
  const history = useHistory();

  history.listen((location) => {
    emit(PostMessageEvent.ROUTE_CHANGE, { routeName: getRouteName(location.pathname) });
  });

  return (
    <Switch>
      {/* Public pages */}

      <Route exact path={Routes.HOME.path} component={LoginPage} />
      <Route exact path={Routes.REGISTRATION.path} component={RegistrationPage} />
      <Route exact path={Routes.VERIFICATION.path} component={VerificationPage} />
      <Route exact path={Routes.FORGOT_PASSWORD.path} component={ForgotPasswordPage} />
      <Route exact path={Routes.NEW_PASSWORD.path} component={NewPasswordPage} />
      <Route exact path={Routes.PASSWORD_RESET.path} component={PasswordResetSuccessPage} />
      <Route exact path={Routes.REGISTRATION_SUCCESS.path} component={SuccessPage} />
      <Route exact path={Routes.MIGRATION_SUCCESS.path} component={MigrationSuccessPage} />
      <Route exact path={Routes.UPDATE_PASSWORD.path} component={UpdatePasswordPage} />

      {/* Embeddable pages */}

      <Route exact path={Routes.EMBEDDED_FORGOT_PASSWORD.path} component={EmbeddedForgotPasswordPage} />
      <Route exact path={Routes.EMBEDDED_LOGIN.path} component={EmbeddedLoginPage} />
      <Route exact path={Routes.EMBEDDED_MIGRATION_SUCCESS.path} component={EmbeddedMigrationSuccessPage} />
      <Route exact path={Routes.EMBEDDED_NEW_PASSWORD.path} component={EmbeddedNewPasswordPage} />
      <Route exact path={Routes.EMBEDDED_PASSWORD_RESET.path} component={EmbeddedPasswordResetPage} />
      <Route exact path={Routes.EMBEDDED_REGISTRATION.path} component={EmbeddedRegistrationPage} />
      <Route exact path={Routes.EMBEDDED_REGISTRATION_SUCCESS.path} component={EmbeddedSuccessPage} />
      <Route exact path={Routes.EMBEDDED_UPDATE_PASSWORD.path} component={EmbeddedUpdatePasswordPage} />
      <Route exact path={Routes.EMBEDDED_VERIFICATION.path} component={EmbeddedVerificationPage} />

      {/* Private pages */}

      <PrivateRoute exact path={Routes.PROFILE.path} component={ProfilePage} />
      <PrivateRoute exact path={Routes.CHANGE_NAME.path} component={EditProfilePage} />
      <PrivateRoute exact path={Routes.CHANGE_BIO.path} component={EditProfilePage} />
      <PrivateRoute exact path={Routes.CHANGE_AVATAR.path} component={EditProfilePage} />
      <PrivateRoute exact path={Routes.PRODUCTS.path} component={ProductsPage} />
      <PrivateRoute exact path={Routes.SETTINGS.path} component={SettingsPage} />
      <PrivateRoute path={Routes.CHANGE_PASSWORD.path} component={EnterNewPasswordPage} />
      <PrivateRoute exact path={Routes.NOTIFICATIONS.path} component={NotificationsPage} />
      {ENV !== 'production' && <Route path={Routes.TEST.path} component={TestPage} />}
    </Switch>
  );
};

export default AppRoutes;
