import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import { Environment, getAuthConfiguration } from '@britannica/cam-utils';
import AppRoutes from './App.routes';
import { ENV } from './constants';
import { GlobalProvider } from './context/GlobalState';

const App = () => {
  Auth.configure(getAuthConfiguration(ENV, { secure: ENV === Environment.PRODUCTION }));

  return (
    <BrowserRouter>
      <GlobalProvider>
        <AppRoutes />
      </GlobalProvider>
    </BrowserRouter>
  );
};

export default App;
