import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@britannica/ui';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './LoadingButton.module.scss';

const LoadingButton = ({ children, className, color, disabled, loading, ...rest }) => (
  <Button
    className={classnames(styles.LoadingButton, className, { [styles.loading]: loading })}
    color={color}
    disabled={loading || disabled}
    data-testid="LoadingButton"
    {...rest}
  >
    <span className={styles.label}>{children}</span>
    {loading && <CircularProgress className="position-absolute" color="inherit" size={16} />}
  </Button>
);

LoadingButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

LoadingButton.defaultProps = {
  children: 'Submit',
  className: null,
  color: 'primary',
  disabled: false,
  loading: false,
};

export default LoadingButton;
