import React, { lazy, Suspense } from 'react';

const LazyEmbeddedVerificationPage = lazy(() => import('./EmbeddedVerificationPage'));

const EmbeddedVerificationPage = (props) => (
  <Suspense fallback={null}>
    <LazyEmbeddedVerificationPage {...props} />
  </Suspense>
);

export default EmbeddedVerificationPage;
