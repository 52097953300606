import React, { lazy, Suspense } from 'react';

const LazyEmbeddedPasswordResetSuccessPage = lazy(() => import('./EmbeddedPasswordResetSuccessPage'));

const EmbeddedPasswordResetSuccessPage = (props) => (
  <Suspense fallback={null}>
    <LazyEmbeddedPasswordResetSuccessPage {...props} />
  </Suspense>
);

export default EmbeddedPasswordResetSuccessPage;
