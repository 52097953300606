import { useEffect, useRef } from 'react';
import { PostMessageEvent } from '@britannica/cam-utils';
import { useHistory } from 'react-router-dom';
import { emit } from '../utils/embed-utils';
import { getRouteName } from '../utils/routing-utils';

export default function useFormValidationEvents(formState) {
  const {
    location: { pathname },
  } = useHistory();
  const formRef = useRef();

  useEffect(() => {
    if (formState.isDirty || formState.submitCount > 0) {
      emit(PostMessageEvent.FORM_VALIDATION_CHANGE, {
        routeName: getRouteName(pathname),
        boundingClientRect: formRef.current.getBoundingClientRect(),
      });
    }
  }, [formState, formRef, pathname]);

  return formRef;
}
