import React, { lazy, Suspense } from 'react';

const LazyEmbeddedMigrationSuccessPage = lazy(() => import('./EmbeddedMigrationSuccessPage'));

const EmbeddedMigrationSuccessPage = (props) => (
  <Suspense fallback={null}>
    <LazyEmbeddedMigrationSuccessPage {...props} />
  </Suspense>
);

export default EmbeddedMigrationSuccessPage;
