import React from 'react';
import { Helmet } from 'react-helmet';
import HeaderLogoUrl from '../../assets/logo.svg';
import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import BaseCard from '../../components/BaseCard/BaseCard';
import VerificationForm from '../../components/VerificationForm/VerificationForm';

const VerificationPage = () => (
  <DefaultTemplate>
    <Helmet>
      <title>Verify Account</title>
    </Helmet>
    <BaseCard>
      <img src={HeaderLogoUrl} className="d-block d-md-none mb-10 mx-auto" alt="Britannica logo" width={220} />
      <VerificationForm />
      <div className="mt-15 text-center font-weight-bold">
        <a href="http://forms01.britannica.com/help/bpshelpform.html" target="_blank" rel="noopener noreferrer">
          Need help?
        </a>
      </div>
    </BaseCard>
  </DefaultTemplate>
);

VerificationPage.propTypes = {};

VerificationPage.defaultProps = {};

export default VerificationPage;
