import React, { useState, useEffect, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Auth } from '@aws-amplify/auth';
import { GlobalContext } from '../../context/GlobalState';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { updateProfile } = useContext(GlobalContext);
  const [user, setUser] = useState();
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    let ignore = false;

    async function fetchData() {
      const activeUser = await Auth.currentAuthenticatedUser({ bypassCache: true })
        .then((cognitoUser) => cognitoUser.username !== 'anonymous')
        .catch((cognitoUser) => false);

      if (!ignore) {
        setUser(activeUser);
        setIsLoading(false);
      }

      setIsLoading(false);
    }

    fetchData();

    if (!ignore) {
      updateProfile();
    }

    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          <Component {...location} />
        ) : (
          !loading && (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          )
        )
      } // eslint-disable-line
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
};

PrivateRoute.defaultProps = {
  component: null,
};

export default PrivateRoute;
