import { SELECT_PROFILE, ERROR_PROFILE, LOADING_PROFILE } from '../constants';

export default (state, action) => {
  switch (action.type) {
    case SELECT_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case ERROR_PROFILE:
      return {
        ...state,
        errorProfile: action.payload,
      };
    case LOADING_PROFILE:
      return {
        ...state,
        loadingProfile: action.payload,
      };
    default:
      return state;
  }
};
