import React, { lazy, Suspense } from 'react';

const LazyNewPasswordPage = lazy(() => import('./NewPasswordPage'));

const NewPasswordPage = (props) => (
  <Suspense fallback={null}>
    <LazyNewPasswordPage {...props} />
  </Suspense>
);

export default NewPasswordPage;
