import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import AppReducer from './AppReducer';
import { SELECT_PROFILE, ERROR_PROFILE, LOADING_PROFILE } from '../constants';
import CamService from '../services/cam-service';

// Initial state
const initialState = {
  profile: null,
  loadingProfile: false,
  errorProfile: null,
};

// Create context
export const GlobalContext = createContext(initialState);

// Provider component
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // Actions
  function updateProfile() {
    dispatch({
      type: LOADING_PROFILE,
      payload: true,
    });
    CamService.fetchUserProfile()
      .then(({ profile: profileData }) => {
        dispatch({
          type: SELECT_PROFILE,
          payload: profileData,
        });
        dispatch({
          type: LOADING_PROFILE,
          payload: false,
        });
      })
      .catch((err) => {
        dispatch({
          type: ERROR_PROFILE,
          payload: err.message,
        });
        dispatch({
          type: LOADING_PROFILE,
          payload: false,
        });
      });
  }

  return (
    <GlobalContext.Provider
      value={{
        profile: state.profile,
        loadingProfile: state.loadingProfile,
        errorProfile: state.errorProfile,
        updateProfile,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

GlobalProvider.propTypes = {
  children: PropTypes.shape(),
};

GlobalProvider.defaultProps = {
  children: null,
};
