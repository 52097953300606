import React, { lazy, Suspense } from 'react';

const LazyPasswordResetSuccessPage = lazy(() => import('./PasswordResetSuccessPage'));

const PasswordResetSuccessPage = (props) => (
  <Suspense fallback={null}>
    <LazyPasswordResetSuccessPage {...props} />
  </Suspense>
);

export default PasswordResetSuccessPage;
