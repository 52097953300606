import React, { lazy, Suspense } from 'react';

const LazyMigrationSuccessPage = lazy(() => import('./MigrationSuccessPage'));

const MigrationSuccessPage = (props) => (
  <Suspense fallback={null}>
    <LazyMigrationSuccessPage {...props} />
  </Suspense>
);

export default MigrationSuccessPage;
