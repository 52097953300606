import React, { lazy, Suspense } from 'react';

const LazyEmbeddedLoginPage = lazy(() => import('./EmbeddedLoginPage'));

const EmbeddedLoginPage = (props) => (
  <Suspense fallback={null}>
    <LazyEmbeddedLoginPage {...props} />
  </Suspense>
);

export default EmbeddedLoginPage;
