import React, { lazy, Suspense } from 'react';

const LazyUpdatePasswordPage = lazy(() => import('./UpdatePasswordPage'));

const UpdatePasswordPage = (props) => (
  <Suspense fallback={null}>
    <LazyUpdatePasswordPage {...props} />
  </Suspense>
);

export default UpdatePasswordPage;
