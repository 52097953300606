import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from '@britannica/ui';
import { useMediaQuery } from 'react-responsive';
import { ViewportWidth } from '../../constants';

const BaseCard = ({ children, className }) => {
  const isMobile = useMediaQuery({
    maxWidth: ViewportWidth.XS_MAX,
  });

  return (
    <div className="container-fluid">
      <div className="grid" data-testid="BaseCard">
        <div className={classnames(className, 'mx-auto col-100 mt-sm-60 col-sm-50 col-md-40 col-lg-30')}>
          <Card className={classnames({ 'shadow-none': isMobile }, 'bg-white')}>
            <CardBody className="w-100 px-5 py-25  px-sm-20 py-sm-30">{children}</CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

BaseCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

BaseCard.defaultProps = {
  children: null,
  className: null,
};

export default BaseCard;
