import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Footer.module.scss';

const Footer = ({ className, usedInAccount }) => {
  return (
    <footer
      className={classnames(
        styles.Footer,
        className,
        'w-100 pt-15 pb-30',
        { 'pt-30': usedInAccount },
        { [styles.FooterAccount]: usedInAccount }
      )}
      data-testid="Footer"
    >
      <ul className={classnames('list-inline lh-sm font-12 m-0', { 'text-center': !usedInAccount })}>
        <li className={classnames('mt-10', { 'font-weight-bold': usedInAccount })}>
          <a href="https://www.britannica.com/" target="_blank" rel="noopener noreferrer">
            Britannica.com
          </a>
        </li>
        <li className={classnames('mt-10', { 'font-weight-bold': usedInAccount })}>
          <a href="https://beyond.britannica.com/" target="_blank" rel="noopener noreferrer">
            Britannica Beyond
          </a>
        </li>
        <li className={classnames('mt-10', { 'font-weight-bold': usedInAccount })}>
          <a href="https://kids.britannica.com/" target="_blank" rel="noopener noreferrer">
            {' '}
            Britannica Kids
          </a>
        </li>
        {usedInAccount && <br />}
        <li className={classnames('mt-10', { 'font-weight-bold': usedInAccount })}>
          <a href="https://corporate.britannica.com/termsofuse.html" target="_blank" rel="noopener noreferrer">
            {' '}
            Terms of Use{' '}
          </a>
        </li>
        <li className={classnames('mt-10', { 'font-weight-bold': usedInAccount })}>
          <a href="https://corporate.britannica.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </li>
        {usedInAccount && <br />}
        <li className="mt-10">
          <span className="text-gray-700"> &copy; {new Date().getFullYear()} Encyclop&aelig;dia Britannica, Inc.</span>
        </li>
      </ul>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  usedInAccount: PropTypes.bool,
};

Footer.defaultProps = {
  className: null,
  usedInAccount: false,
};

export default Footer;
