import React, { lazy, Suspense } from 'react';

const LazyEnterNewPasswordPage = lazy(() => import('./EnterNewPasswordPage'));

const EnterNewPasswordPage = (props) => (
  <Suspense fallback={null}>
    <LazyEnterNewPasswordPage {...props} />
  </Suspense>
);

export default EnterNewPasswordPage;
