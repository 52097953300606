import PropTypes from 'prop-types';
import React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@britannica/ui';

const CustomToast = ({ children, onClosed, success }) => {
  const toggle = () => {
    onClosed();
  };

  return (
    <div className="mt-25">
      <div className="d-flex align-items-center justify-content-between shadow p-10">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            {!success && <ErrorOutlineIcon className="mr-10" style={{ color: '#9A2222', fontSize: 30 }} />}
            {success && <CheckCircleOutlineIcon className="mr-10" style={{ color: 'green', fontSize: 30 }} />}
            <div className="border-left py-5 pl-15 pr-5 font-serif font-14">{children}</div>
          </div>
        </div>
        <Button label="Close" onClick={toggle} className="p-10">
          <CloseIcon />
        </Button>
      </div>
    </div>
  );
};

CustomToast.propTypes = {
  children: PropTypes.node,
  onClosed: PropTypes.func,
  success: PropTypes.bool,
};

CustomToast.defaultProps = {
  children: null,
  onClosed: () => {},
  success: false,
};

export default CustomToast;
