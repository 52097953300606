import React, { lazy, Suspense } from 'react';

const LazyNotificationsPage = lazy(() => import('./NotificationsPage'));

const NotificationsPage = (props) => (
  <Suspense fallback={null}>
    <LazyNotificationsPage {...props} />
  </Suspense>
);

export default NotificationsPage;
