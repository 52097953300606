import React, { lazy, Suspense } from 'react';

const LazyEmbeddedUpdatePasswordPage = lazy(() => import('./EmbeddedUpdatePasswordPage'));

const EmbeddedUpdatePasswordPage = (props) => (
  <Suspense fallback={null}>
    <LazyEmbeddedUpdatePasswordPage {...props} />
  </Suspense>
);

export default EmbeddedUpdatePasswordPage;
