import React, { lazy, Suspense } from 'react';

const LazyEditProfilePage = lazy(() => import('./EditProfilePage'));

const EditProfilePage = (props) => (
  <Suspense fallback={<></>}>
    <LazyEditProfilePage {...props} />
  </Suspense>
);

export default EditProfilePage;
